/**
 * Changelog
 */
export const changelog = [
    {
        version: "1.13",
        date: "2025-Mar-23",
        added: [],
        changed: [],
        fixed: [
            {
                text: "Updated mailserver settings to new hoster."
            }
        ]
    },
    {
        version: "1.12",
        date: "2024-Dec-16",
        added: [],
        changed: [],
        fixed: [
            {
                text: "Reports/Overhead Reports - Minor fix for manually selected Start and End date when querying data for all projects. The data was correct but the visibly presented Start and End date were the original ones from all projects."
            }
        ]
    },
    {
        version: "1.11",
        date: "2024-Dec-15",
        added: [],
        changed: [],
        fixed: [
            {
                text: "Reports/Overhead Reports - Minor syntax and compiler fixes. Removed the feature to clean the internal project id from task names to avoid confusion."
            }
        ]
    },
    {
        version: "1.10",
        date: "2024-Dec-14",
        added: [
            {
                text: "Reports/Overhead Reports - Added new report page for overhead reports including an export to CSV."
            }
        ],
        changed: [],
        fixed: [
            {
                text: "Reports/Project Reports - Fixed an issue which caused the 'End Date' field to be marked as red with an invalid value when selecting a new project after initially selecting another project on that page."
            }
        ]
    },
    {
        version: "1.09",
        date: "2024-Jul-15",
        added: [],
        changed: [],
        fixed: [
            {
                text: "Admin/Absences - Added new feature to only allow certain absences (e.g. vacation, compensation day) with approval of admin user or team lead."
            }
        ]
    },
    {
        version: "1.08",
        date: "2024-May-17",
        added: [],
        changed: [],
        fixed: [
            {
                text: "Accountings - Modified amount of days which can pass until the accounting for a day is locked and cannot be modified from 28 to 14."
            }
        ]
    },
    {
        version: "1.07",
        date: "2023-Nov-21",
        added: [],
        changed: [],
        fixed: [
            {
                text: "Accountings - Fixed an issue where the amount of breaks taken has not been properly calculated if the breaks happened between two work time entries."
            },
            {
                text: "Accountings - Fixed an issue where work time entries could not be saved due to a warning that the accounted time is greater than the entered work time even though everything was entered correctly."
            }
        ]
    },
    {
        version: "1.06",
        date: "2023-Nov-08",
        added: [
            {
                text: "Admin/Contracts - Added a new comment field which can be set for each contract."
            },
            {
                text: "Admin/Contracts - The comment is now displayed as a tooltip if you hover over the comment text field."
            },
            {
                text: "Admin/Projects - The description is now displayed as a tooltip if you hover over the description text field."
            }
        ],
        changed: [
            {
                text: "Accountings - Quality of life improvement: If you modify the start time while entering work hours, the respective end time is now adjusted accordingly. You are still able to freely change the end time if you want."
            }
        ],
        fixed: [
            {
                text: "Absences - Fixed an issue which caused vacation days to be created on holidays if the vacation range spanned from one year to the next."
            }
        ]
    },
    {
        version: "1.05",
        date: "2023-Jul-01",
        added: [],
        changed: [],
        fixed: [
            {
                text: "Accountings - Fixed an issue which caused break times to be not properly calculated when entering your daily work times split into multiple work time entries."
            }
        ]
    },
    {
        version: "1.04",
        date: "2023-Mar-30",
        added: [],
        changed: [
            {
                text: "Accountings & Work Time Reports - Work time button for the respective day is only showing up if time has been accounted on that day. Solves issue with people not regularly working each day of the week."
            }
        ],
        fixed: [
            {
                text: "Accountings - Fixed issue with work time warning for insufficient rest periods not properly appearing."
            }
        ]
    },
    {
        version: "1.03",
        date: "2023-Mar-04",
        added: [
            {
                text: "Accountings - New Feature for work time tracking visible via the clock symbol at each day. By clicking on it times can be tracked. The color of the clock and its tooltip when you hover over it shows if there are entries (green), no entries (red) or warnings (yellow). Grey clocks do not need entries (on weekends and non-working days) but entries are also supported on these days if necessary."
            },
            {
                text: "My Account - Added ability to set a custom work start time each day which will be used when auto generating the work times."
            },
            {
                text: "Reports - Both the tabs My Reports as well as Team Reports now also feature a Work Times selection which allows to view all tracked work times in the selected month in a table."
            },
            {
                text: "Admin/Projects and Projects - Added info when the project was created as well as when the project status was last updated."
            },
            {
                text: "Admin/Projects and Projects - Added info when the task was created as well as when the task status was last updated."
            },
            {
                text: "Reports/Project Reports - Date when the selected project was created as well as when the project status was last updated is now shown in an additional details table."
            },
            {
                text: "Reports/Project Reports - Date when the selected task was created as well as when the task status was last updated is now shown in an additional details table."
            },
            {
                text: "Reports/Project Reports - Added table at the bottom, showing all the related accounting entries matching the selections made above."
            },
            {
                text: "Reports/Project Reports - Added CSV export button for all selected accounting entries of the project."
            },
            {
                text: "Top Bar - Re-added bug report icon. Click to send an email with your bug report."
            }
        ],
        changed: [
            {
                text: "Reports/My Reports and Reports/Team Reports - Added circle markers to accountings graph to show info even if no graph is displayed at the beginning of the year."
            },
            {
                text: "Reports - Reworked the look and size of the CSV export buttons to allow for more options and clarify the export options available to the user."
            }
        ],
        fixed: [
            {
                text: "Accountings - Fixed a graphical issue which caused the project name rows of the accounting table to be overlapped by the daily entries if the user screen was too small to display the full month."
            },
            {
                text: "Reports/Project Reports - Fixed an issue where off-the-chart dates were displayed as Start and End Date of the project if no hour was yet accounted on a project."
            },
	        {
		        text: "Accountings and Absences - Fixed issue with red outlines not properly drawn to resemble today on the weekends and on public holidays."
    	    }
	    ]
    },
    {
        version: "1.02",
        date: "2022-Nov-28",
        added: [
            {
		        text: "Reports - Added Project report tab and associated functions for global project admins and project leads."
    	    }
        ],
        changed: [],
        fixed: [
	        {
		        text: "Reports/My Reports - Fixed overtime hour entries in table and chart in report. The signs were reversed since the change done in v0.99."
    	    },
            {
		        text: "Reports/Accountings - Fixed overtime hour entries in table and chart in report. The signs were reversed since the change done in v0.99."
    	    }
	    ]
    },
    {
        version: "1.01",
        date: "2022-Nov-05",
        added: [],
        changed: [],
        fixed: [
	    {
		    text: "Fix for lost password mail settings to properly handle self-signed certificate of the mail server."
	    }
	]
    },
    {
        version: "1.00",
        date: "2022-Nov-05",
        added: [],
        changed: [
            {
                text: "Navigation - Removed the bug report icon on the top of the navigation bar."
            },
	    {
		text: "Lost Password Email - Changed the email address this mail comes from to time@atmosphere.aero."
	    }
        ],
        fixed: []
    },
    {
        version: "0.99",
        date: "2022-Nov-02",
        added: [],
        changed: [
            {
                text: "Accountings - Modified summary field 'Remaining Hours this Month' to be positive if there are still hours left to be booked. It was negative before which was confusing."
            }
        ],
        fixed: [
            {
                text: "Absences - Fixed rounding issue with fractions of vacation days. Fractions of vacation days always need to be rounded up by german law."
            },
            {
                text: "Admin - Make search case insensitive on all admin pages (Users, UserTypes, Roles, Teams, Projects, Holidays, Absences)."
            }
        ]
    },
    {
        version: "0.98",
        date: "2022-Aug-01",
        added: [],
        changed: [
            {
                text: "Increased the locking period of the time accounting table from 14 days to 28 days."
            }
        ],
        fixed: []
    },
    {
        version: "0.97",
        date: "2022-May-17",
        added: [],
        changed: [
            {
                text: "Accountings - Changed the order of values in the 'Accounting Summary' for the displayed month, shifting the 'Adjusted Hours this Month' before 'Remaining Hours this Month' to indicate that the adjustment has already been calculated into the remaining hours."
            },
            {
                text: "Accountings - Changed the calculation of 'Overtime Hours Today' to include all adjustments made within the displayed month. Previously the 'Overtime Hours Today' would only reflect adjustments which happened in the displayed month up until today. So adjustments at the end of the month would not be calculated into this value in the middle of the month which makes no sense from an usability point of view."
            },
            {
                text: "Reports/Accountings - Changed the order of the columns and moved 'Adjusted Hours' before 'Overtime Hours' in the overview table to properly indicated that the shown 'Overtime Hours' already include the 'Adjusted Hours' value. Moved the columns of these values in the CSV export as well."
            },
            {
                text: "Accountings & Absences - Modified the way 'Today' is displayed in the calendars, removing the gradient background color since it seemed too conflicting with the background colors of selected absence reasons. Now today is marked with a red border instead to make it more visible."
            },
            {
                text: "Contracts - Changed type of work hours per week and work hours at start of contract to be a floating number instead of an integer."
            },
            {
                text: "Misc - Added width and height to all multi-line text fields like comments on hour accounting and others to easy up entering text."
            }
        ],
        fixed: [
            {
                text: "All Modal Dialogs - The 'Ok' and 'Cancel' buttons are now properly at the bottom of the dialog on smaller screens. So you need to scroll down, using the displayed scroll bar, to get to them. This avoids confusion about 'Ok' buttons not enabled due to missing mandatory dialog values which were only visible by scrolling."
            },
            {
                text: "All Modal Dialogs - The dialogs now properly open hovering above the top bar and also above the left-hand menu bar of the application. This avoids elements of the dialog being hidden on smaller screens."
            }
        ]
    },
    {
        version: "0.96",
        date: "2021-Nov-15",
        added: [],
        changed: [
            {
                text: "Reports - Added new Tab 'Company Reports' for users with admin-absences permission. It currently offers a possibility to view absences of all employees and how many employees were in the office at a certain day. Related to work safety and mandatory home office regulations."
            },
            {
                text: "Accountings & Absences - Changed the background color of todays' date to a custom gradient to make it more easily visible. Previously the existing static color for today did not do its' job if an absence reason was selected on the same day which had a similar color."
            }
        ],
        fixed: [
            {
                text: "Reports - Fixed an issue in the absence report CSV export, some translation strings were missing."
            }
        ]
    },
    {
        version: "0.95",
        date: "2021-Nov-09",
        added: [],
        changed: [
            {
                text: "Accountings - Modified the behavior of automatically accounting absences. Up till now the auto accounting absences entered their hours (when added in the absence calendar) or removed their hours (when deleted from the absence calendar). They did not delete existing hours from another absence which was possibly previously selected for this day. This has now changed. Since there can only be one selected absence reason on a day, hours entered on another absences reason are removed when a new absence reason is selected. Hours which were added to a project task on that day are not removed, so please be aware of that when selecting an absence reason which has auto accounting enabled (like illness or vacations)."
            }
        ],
        fixed: [
            {
                text: "Accountings - Fixed an issue where it became impossible to remove hours of an absence reason which the user entered manually. This happened in certain cases when an absence reason was first selected and later changed to a different reason."
            },
            {
                text: "Admin/Contracts - Fixed an issue which made adding a new contract impossible because the new default value for work_hours_at_start was not always provided."
            }
        ]
    },
    {
        version: "0.94",
        date: "2021-Oct-27",
        added: [
            {
                text: "Main Bar - Added tooltips for all buttons in the top main bar"
            },
            {
                text: "Main Bar - Added bug report icon which automatically opens your email client with the proper email address set so you can directly open a ticket in case something went wrong."
            },
            {
                text: "Admin/Contracts - Added ability to set a plus or minus initial work hours at the start of a contract."
            },
            {
                text: "Admin/Adjustments - Added entry for initial work hours at start of contract to show them together with other manual adjustments. They are just for reference and cannot be modified on this page."
            },
            {
                text: "My Profile - Added ability to see the work hours at start of a contract for the currently logged in user."
            },
            {
                text: "Reports/Accounting - Properly takes hours at the start of the contract into account in the first contract month of the column 'Adjusted Hours'."
            },
            {
                text: "Accountings - Properly takes hours at the start of the contract into account in the first contract month of the column 'Adjusted Hours'."
            },
            {
                text: "Admin/Adjustments - Added ability to make manual adjustments of vacation days during the contract."
            },
            {
                text: "Absences - Show summary of adjusted vacation days per year in its own field 'Days adjusted' in the vacation day summary for the selected year."
            },
            {
                text: "Reports/Vacations - Properly takes adjustments of vacation days into account and shows them as a summary for the year, in a separate column per month and as a column in the chart. The CSV export also properly includes these adjustments now."
            }
        ],
        changed: [
            {
                text: "Admin/Accountings: Modified the name of the menu element from 'Accountings' to 'Adjustments' to make it more clear."
            },
            {
                text: "Accountings - Renamed all instances of 'Corrected Hours' to 'Adjusted Hours' to be in-line with renaming of the Admin/Accountings page."
            },
            {
                text: "Reports/Accountings - Renamed all instances of 'Corrected Hours' to 'Adjusted Hours' to be in-line with renaming of the Admin/Accountings page."
            }
        ],
        fixed: [
            {
                text: "Reports - Fixed minor issue in displaying manually adjusted hours. Now hours in future months are also displayed correctly and properly handled in the CSV export."
            },
            {
                text: "Accountings - Fixed an issue that a user was able to enter work hours outside of his/her current contract if two contracts existed in a row with a certain time gap in-between."
            }
        ]
    },
    {
        version: "0.93",
        date: "2021-Oct-14",
        added: [],
        changed: [],
        fixed: [
            {
                text: "Reports - Fixed minor issue in vacation CSV export. The months were not iterated correctly causing wrong outputs in the CSV file."
            }
        ]
    },
    {
        version: "0.92",
        date: "2021-Oct-14",
        added: [
            {
                text: "Admin Contracts - Added a new field to allow setting of vacation days at start of contract. This value is zero per default but can be set to plus or minus vacation days which are calculated in the starting year of the contract."
            },
            {
                text: "Reports/Absences - Modified the calculation of vacation days available in the current year by including the vacation days at the start of the contract in the first contract year."
            },
            {
                text: "My Profile - Added new field for vacation days at start of contract to the user's contracts on the profile page."
            },
            {
                text: "Reports - Added button for a CSV export of vacations, accountings and absences to the 'My Reports' section."
            },
            {
                text: "Reports - Added button for a CSV export of vacations, accountings and absences to the 'Team Reports' section, either exporting the selected user or all users."
            }
        ],
        changed: [
            {
                text: "Accountings - Changed the text 'Corrected Hours' to 'Corrected Hours this Month' in the Accounting Summary section to make it clear that this information relates to the currently selected month. Thanks to Nati for pointing this out!"
            },
            {
                text: "Accountings - Moved the 'Corrected Hours this Month' field one step to the left to show it together with all other values related to this month. Since the last field 'Overtime Hours Today' always relate to today and not the selected month."
            },
            {
                text: "Accountings - Added a border between the values which are depending on the current month and the 'Overtime Hours Today' to make it more clear that this value is based on todays' date and not on the selected month."
            },
            {
                text: "Absences - Applied a similar change with an additional border to the 'Overtime Hours Today' on the Absences page summaries to separate this value from the others."
            }
        ],
        fixed: [
            {
                text: "Admin Accountings - Fixed an issue that prevented a new accounting correction from being entered if an user had more than two contracts assigned. Thanks Melina for reporting!"
            },
            {
                text: "Admin Accountings - Fixed an issue that it was not possible to remove an existing comment."
            },
            {
                text: "Admin Accountings - Fixed an optical issue with a missing border between the Hours and Comment column."
            },
            {
                text: "Admin Accountings - Prohibited the ability to completely empty the Hours field of an entry, which resulted in a database error when being saved."
            }
        ]
    },
    {
        version: "0.91",
        date: "2021-Sep-15",
        added: [],
        changed: [],
        fixed: [
            {
                text: "Admin Accountings - Fixed minor issue that missing absence reason for accounting corrections error was not showing up properly."
            }
        ]
    },
    {
        version: "0.9",
        date: "2021-Sep-15",
        added: [
            {
                text: "Admin Accountings - Added new admin page for Accountings. Allowing users with proper permissions to manually correct accounting hours outside of the regular accounting per project."
            },
            {
                text: "Accountings - Added additional information about corrected accounting hours to the summary table."
            },
            {
                text: "Reports - Added additional information about corrected accounting hours to the report accounting table as well as a separate bar to the chart."
            },
            {
                text: "Admin Holidays - Added ability for admin users to add, modify and delete custom repeatable holidays."
            },
            {
                text: "Absences - Added filter for absences in Company Calendar to only show users with an active contract in the selected year and month."
            },
            {
                text: "Reports - Added new Absences report, to give an overview of booked absences over the years."
            }
        ],
        changed: [
            {
                text: "Accountings - Days in which an absence reason was selected which automatically enters hours (like vacations) are now locked. No additional accountings can be stored on these days. Only admins with the proper role can do that. If a day is locked is indicated by a red bar at the top (same mechanism as if the accounting is outside of the rolling window of days)."
            },
            {
                text: "Team Accountings - Admins with the proper permissions are now able to manually modify the automatic accounted hours on absences (like vacations)."
            },
            {
                text: "Accountings & Absences - Added sticky columns on tables 'My Accountings', 'Project Accountings' and 'Absences - Company Calendar'. This allow the table to scroll on smaller screens while still keeping employee, task or project names visible to the user. Thanks to Nati for this suggestion!"
            },
            {
                text: "Reports - Modified all the graphs in the reports section to be more easily readable."
            }
        ],
        fixed: [
            {
                text: "Accounting - Fixed an issue where comments were deleted when they were added in a field before the hours were actually entered and saved. Thanks to Hisham for reporting this bug!"
            },
            {
                text: "Admin Holidays - Fixed an issue with the selected year not staying consistent when switching between the calendar, list view, and generate holiday tabs."
            },
            {
                text: "Admin Holidays - Fixed an issue with the selected year not still being selected after a manual reload of the backend data."
            },
            {
                text: "Absences - Fixed issue with absences not properly getting removed if an absence is updated and a new start or end date is selected by the user. Thanks to Markus for reporting this problem!"
            },
            {
                text: "Message of the Day - Fixed issue with title and button text of message of the day. The language setting of the user was not yet properly loaded at rendering time."
            }
        ]
    },
    {
        version: "0.8",
        date: "2021-Aug-01",
        added: [
            {
                text: "Absences - Added new value to the Vacation Day Summary to display the overall overtime hours the user has as of today."
            },
            {
                text: "Accountings - Added new value to the Accounting Summary to display the overall overtime hours the user has as of today both to tab my accountings and tab team accountings."
            },
            {
                text: "Reports - Added new reports page with the ability to show your own Vacation and Accounting report as well as your team's report if you have the proper permissions."
            },
            {
                text: "Backend API - Added ability to calculate yearly and monthly accounting and absence reports to the backend API to ease up load on the frontend."
            }
        ],
        changed: [
            {
                text: "Absences - Limited ability to switch to future years. Only one year into the future is now possible."
            },
            {
                text: "Absences - Modified texts in Vacation Day Summary section to avoid confusion where the values are coming from."
            },
            {
                text: "Absences - Using new absence report generated by the backend API when displaying the vacation day summary to the user."
            },
            {
                text: "Accountings - Limited ability to switch to future years. Only one year into the future is now possible."
            },
            {
                text: "Accountings - Using new accounting report generated by the backend API when displaying the Accounting Summary to the user."
            }
        ],
        fixed: [
            {
                text: "Accountings - Improved load on backend by limiting requested data on the accountings page to what is really needed."
            },
            {
                text: "Absences - Fixed a bug in which below 0.5 vacation day fractions were rounded up instead of rounded down."
            }
        ]
    },
    {
        version: "0.7",
        date: "2021-Jun-26",
        added: [
            {
                text: "Accountings - No modification is allowed anymore of accounted time on absence reasons which do auto-accounting based on the users active contract. Since it does not make sense. This lock is indicated in the Accountings table by displaying the same lock icon as for already closed tasks."
            },
            {
                text: "Accountings - Added configurable auto locking time window. The user is only able to edit and delete accounting hours within the configured time window in days. This time window is indicated to the user by a small green (allowed) or red (not allowed) bar at the top of his accounting table. Currently this time window is set to 14 days in the past, based on the current day. Accountings into the future are always allowed. Past accountings which need to be modified after the time window has passed need to be changed by an User with the Admin Accountings or Global Admin Role."
            },
            {
                text: "About Page - Added table showing the new backend configuration items."
            },
            {
                text: "Backend API - Added ability to query general configuration items to the backend API to make these consistent in frontend and backend."
            }
        ],
        changed: [
            {
                text: "Config - Moved configuration items for rounding of accounting hours as well as maximum hours per day to the backend config. Removed the items from the frontend, the frontend is querying them now from the backend."
            },
            {
                text: "User Email Addresses - Ensured that user email addresses are always stored and used in lower-case style. No matter how the administrative user entered it to avoid confusion during login."
            },
            {
                text: "Backend API - Added rolling time window check for accountings also to the backend API to make sure modifying accountings if they passed the time window are not allowed. Only allowed by users which have the Admin Accountings role or Global Admins."
            },
            {
                text: "Backend API - Added proper handling of rounding accounting hours to the backend API."
            }
        ],
        fixed: [
            {
                text: "Accountings - Fixed an issue where automatically accounted hours due to absence reason were not obeying the configured rounding of hours."
            }
        ]
    },
    {
        version: "0.61",
        date: "2021-Jun-08",
        added: [],
        changed: [],
        fixed: [
            {
                text: "Fix - The update v0.6 caused an issue in the time accounting table. The user was not able to select other months anymore in the time accounting due to the added UserType feature. This has been fixed."
            }
        ]
    },
    {
        version: "0.6",
        date: "2021-Jun-05",
        added: [
            {
                text: "User Types - It is now possible to add custom user types on the new Admin User Types page. Users having administrative rights on the user list can then set such a user type per user (for example Student, VIE etc). The user types can be set to visible or not visible. If they are not visible they will only show up to users with an administrative role, team leads and project leads. If the type is visible it will show up to all users. All lists and pull-down boxes are now automatically sorted first by user type then by lastname. This groups all users of the same type together. If no user type is set for a user nothing will show up in these list elements. This is the default state and should be okay for all regular employees. You can check your own user type in the first paragraph on the Profile page."
            }
        ],
        changed: [
            {
                text: "Translations - Moved absence type, project status and task status translations from config to translation file to make the config independent of the used translation."
            }
        ],
        fixed: []
    },
    {
        version: "0.5",
        date: "2021-May-22",
        added: [
            {
                text: "Accountings - Properly show and hide tasks and project depending on their open and close states."
            },
            {
                text: "Accountings - Disallow booking of hours on closed tasks but still show booked hours."
            },
            {
                text: "Accountings - Show an icon indicating a closed task in the task line if no hours can be booked on this task anymore."
            },
            {
                text: "Admin Projects Page - Added an alert dialog which shows up in case a project with still open tasks is set to closed or done."
            },
            {
                text: "Admin Projects Page - Automatically close all open tasks of a project if the project status is set to closed or done after dialog confirmation."
            },
            {
                text: "Admin Tasks Page - Added an alert dialog which shows up in case the user tries to open a task of an already closed or done project."
            },
            {
                text: "Admin Tasks Page - Automatically re-open a closed or done project if a task within the project is opened again after dialog confirmation."
            },
        ],
        changed: [],
        fixed: []
    },
    {
        version: "0.4",
        date: "2021-May-08",
        added: [
            {
                text: "Accountings - Allow people to use commas instead of dots when entering fractions of hours. The comma gets automatically converted to a dot - because reasons."
            },
            {
                text: "Accountings - Mark exceeded daily work hours in red. Setting allowed daily work hours to 10 because you guys need to take care of your work-life-balance."
            },
            {
                text: "First Login Reset Password - Give proper feedback that password was changed successfully and automatically redirect the user back to the login page after a short delay."
            },
            {
                text: "Forgot Password - After successfully sending the reset password email automatically redirect the user back to the login page after a short delay."
            },
            {
                text: "Reset Password - Give proper feedback that password was changed successfully and automatically redirect the user back to the login page after a short delay."
            },
            {
                text: "About Page - Added change log per version."
            }
        ],
        changed: [],
        fixed: [
            {
                text: "Accountings - Properly include public holidays when calculating the sum of needed work hours in the given month because obviously I don't want to mess with your free time."
            },
            {
                text: "Absences - Fixed that users were unable to remove absences when a holiday was added at that day afterwards because why would you want to take a vacation day when there is a public holiday?"
            },
            {
                text: "Accountings Project - When selecting a project in the pull-down menu, only show the users who are assigned to this project in the second pull-down menu."
            },
            {
                text: "Accountings Project - Show the default selected 'All' option in the user pull-down menu. This option was active by default but was not really showing as being set."
            },
            {
                text: "Accountings Project - Corrected wrong font size of accounted hours."
            },
            {
                text: "Admin Projects Page - Allow empty string in project description."  
            },
            {
                text: "Account Page - Fixed text which said 'Current Password' but the weird developer meant 'New Password' instead. At least he implemented the correct functionality."
            }
        ]
    },
    {
        version: "0.3",
        date: "2021-Apr-23",
        added: [
            {
                text: "Modified color of every 2nd line in every table to a darker grey and also changed the associated weekend color on the accounting and absence tabs to also work on certain screens. The previous grey was too light."
            }
        ],
        changed: [],
        fixed: []
    },
    {
        version: "0.2",
        date: "2021-Apr-17",
        added: [
            {
                text: "Added sort function to all tables where it made sense. Click on title of the table column to sort ascending, descending or via a default setting. A small arrow on the column title indicates the sorting order."
            },
            {
                text: "Added the internal project id to several pull down menus in the administrative section as well in the project accountings and user accountings tab."
            },
            {
                text: "Sorting displayed projects in the user accountings tab by project id."
            },
            {
                text: "Changed displayed username to show the lastname first. Makes more sense with the already active sorting order by lastname."
            }
        ],
        changed: [],
        fixed: []
    },
    {
        version: "0.1",
        date: "2021-Mar-28",
        added: [
            {
                text: "Added automatic accounting feature for absence reasons. Can be configured in the administrative section. Automatically accounts the regular daily work hours if the user selects it. Usual use case would be for vacation days."
            }
        ],
        changed: [],
        fixed: []
    },
    {
        version: "0.0",
        date: "2021-Mar-06",
        added: [
            {
                text: "Initial version"
            }
        ],
        changed: [],
        fixed: []
    }
]
